<template>
  <div>
    <div class="nav-box van-hairline--bottom container">
      <div class="nav-left">
        <!-- <van-icon name="wap-nav" size="20"/> -->
        <van-image width="90" :src="logo" @click="pageHandle('/home')" />
      </div>
      <div class="nav-right">
        <hamburger></hamburger>
        <van-icon v-if="!detailForm" name="search" style="margin-right: 20px" size="20" @click="() => (detailForm = !detailForm)" />
        <van-icon v-else name="cross" style="margin-right: 20px" size="20" @click="() => (detailForm = !detailForm)" />
        <van-icon name="location-o" style="margin-right: 20px" size="20" @click="pageHandle('/nearby')" />
        <van-popover v-model="showPopover" trigger="click" placement="bottom-end" :actions="actions" @select="onSelect">
          <template #reference>
            <span type="primary">{{ lan }}</span>
          </template>
        </van-popover>
      </div>
    </div>
    <transition name="van-slide-down">
      <div v-if="detailForm" class="detail-form van-hairline--bottom">
        <el-form label-position="top" :model="searchForm" :inline="true">
          <el-form-item :label="$t('类型')" class="form-label">
            <el-select v-model="searchForm.storeType" clearable style="width: 100%">
              <el-option v-for="(item, name) in shopType" :key="name" :value="name" :label="$t(item)" />
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('消费预算')" class="form-label">
            <el-select v-model="searchForm.dinningType" :placeholder="$t('类型')" clearable class="short-inp" style="width: 32%; margin-right: 10px">
              <el-option v-for="item in dinningTypes" :key="item.value" :value="item.value" :label="$t(item.label)" />
            </el-select>
            <el-select v-model="searchForm.minAmount" :placeholder="$t('下限')" clearable class="short-inp" style="width: 32%; margin-right: 10px">
              <el-option v-for="item in minPriceList" :key="item.value" :value="item.value" :label="$t(item.label)" />
            </el-select>
            <el-select v-model="searchForm.maxAmount" :placeholder="$t('上限')" clearable class="short-inp" style="width: 30%; margin-right: 0px">
              <el-option v-for="item in maxPriceList" :key="item.value" :value="item.value" :label="$t(item.label)" />
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('地区')" class="form-label">
            <el-cascader v-model="searchForm.areaId" class="longSelect" style="width: 100%" :options="areaList" :props="{ value: 'areaId', label: 'areaName' }" clearable @change="handleAreaChange" />
            <!-- <el-select v-model="searchForm.areaId" :placeholder="$t('大阪')">
              <el-option v-for="(item, name) in areaList" :key="name" :value="name" :label="$t(item)" />
            </el-select> -->
          </el-form-item>
          <el-form-item :label="$t('餐厅名称')">
            <el-input v-model="searchForm.keywords" size="small" :placeholder="$t('附近餐厅名称')" />
          </el-form-item>
          <div class="flex-box">
            <el-button class="cost-btn" size="small" icon="el-icon-search" @click="pageHandle('/index', searchForm)">{{ $t("搜索") }}</el-button>
            <!-- <el-button class="width-50" size="small" icon="el-icon-location-information" @click="nearHandle">{{ $t('附近') }}</el-button> -->
          </div>
        </el-form>
      </div>
    </transition>
    <div class="video-box">
      <!-- height="496" -->
      <iframe id="videoIframe" width="100%" height="208" :src="relatedLink" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <div class="video-img-list">
        <van-swipe :loop="false" :show-indicators="false" :width="88">
          <van-swipe-item v-for="(item, index) in videoList" :key="index" class="video-img-item">
            <van-image width="80" height="60" fit="cover" :src="item.content" class="video-img" @click="changeVideo(item.relatedLink)" />
          </van-swipe-item>
          <van-swipe-item class="video-img-item"> </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <div class="bulletin-box container">
      <van-swipe vertical :autoplay="7000" class="buttentin-swiper" :show-indicators="false">
        <van-swipe-item v-for="(item, index) in buttetinList" :key="index">
          <div class="bulletin-content" :style="{ color: item.color ? item.color : '' }">
            {{ $t(item.content) }}
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="activity-box container">
      <!-- :width="310" -->
      <van-swipe :loop="false" :show-indicators="false">
        <van-swipe-item v-for="(item, index) in activityList" :key="index">
          <van-image width="100%" :src="item.bannerImg" @click="pageHandle2(item.relatedLink)" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="special-box container">
      <div class="special-title">{{ $t("限时特集") }}</div>
      <div v-for="item in bannerList" :key="item.id">
        <!-- <a :href="item.relatedLink" target="_self"> -->
        <van-image :src="item.bannerImg" class="banner-item" @click="pageHandle2(item.relatedLink)" />
        <!-- </a> -->
        <!-- {{ item.url[language] }} -->
      </div>
    </div>
    <div class="content-box container">
      <div class="tab-line">
        <div class="tab-box">
          <div class="tab-title active-tab">{{ $t("热门餐厅") }}</div>
          <!-- <div class="tab-title" @click="pageHandle('/department')" >
            {{ $t("观光情报") }}
          </div> -->
        </div>
        <!-- <div class="tab-box">
          <van-tabs swipe-threshold="2" line-width="30%" title-active-color="rgb(249,182,42)">
            <van-tab title-style="tab-title" :title="$t('热门餐厅')" />
            <van-tab title-style="tab-title" :title="$t('观光情报')" to="/department" />
            <van-tab title-style="tab-title" title="PromotionChat" />
          </van-tabs>
        </div> -->
        <div class="tab-all van-hairline--left" @click="pageHandle('/index')">
          {{ $t("全部") }}
        </div>
      </div>
      <div class="content-list">
        <div v-for="(item, index) in dataList2" :key="index" class="content-list-item" @click="detailHandle(item.id)">
          <van-card :thumb="item.img">
            <template #title>
              <div class="van-ellipsis content-item-title">
                {{ item.storeName }}
              </div>
            </template>
            <template #desc>
              <div v-if="item.storeTypes && item.storeTypes.length > 0">
                <van-tag v-for="(type, typeIndex) in item.storeTypes" :key="typeIndex" class="conqtent-item-tag" plain>{{ $t(shopType[type]) }}</van-tag>
              </div>
              <div class="content-item-price">
                <div v-if="item.lunch > 0">
                  <span v-if="item.lunch > 0">{{ $t("午餐") }}：</span>
                  <span v-if="item.lunch > 0" class="color-red">¥ {{ item.lunch | moneyFilter }}</span>
                  <span v-if="item.maxLunch > 0" class="color-red">-{{ item.maxLunch | moneyFilter }}</span>
                </div>
                <div v-if="item.dinner > 0">
                  <span v-if="item.dinner > 0">{{ $t("晚餐") }}：</span>
                  <span v-if="item.dinner > 0" class="color-red">¥ {{ item.dinner | moneyFilter }}</span>
                  <span v-if="item.maxDinner > 0" class="color-red">-{{ item.maxDinner | moneyFilter }}</span>
                </div>
              </div>
              <van-notice-bar v-if="item.featureProvide" :scrollable="false" :text="item.featureProvide" />
            </template>
          </van-card>
        </div>
        <div v-if="dataList.length > 5" v-show="isShow" class="show-more" @click="showMore">
          {{ $t("展示全部") }}
        </div>
      </div>
    </div>
    <div class="link-box container">
      <div class="link-title">{{ $t("相關链接") }}</div>
      <van-row gutter="10" style="display: flex; flex-wrap: wrap; justify-content: stretch">
        <van-col v-for="(item, index) in partnerList" :key="index" span="8">
          <a :href="item.relatedLink">
            <div class="link-item">
              <van-image :src="item.bannerImg" />
              <!-- <div class="link-title">{{ item.title }}</div> -->
            </div>
          </a>
        </van-col>
      </van-row>
    </div>
    <div class="media-box">
      <div v-for="item in mediaList" class="meida-item" :key="item.id">
        <a :href="item.relatedLink">
          <van-image :src="item.bannerImg" round width="40" height="40" style="border: 1px solid #f2f2f2" />
        </a>
      </div>
    </div>
    <div class="footer-info">
      <el-link href="/policy.html" target="_blank" style="color:#fff">Privacy Policy</el-link><br />
      Copyright © Tripellet.com , All rights reserved
    </div>
  </div>
</template>

<script>
import {
  // homeVideoList,
  // homeMediaList,
  // homeActivityList,
  // homeBulletinList,
  minPriceList,
  maxPriceList,
  // homePhBannerList,
  // homeRestIds,
  storeShopType,
  // homePartnerList,
  homeLanList,
} from "@/utils/public_data";
import { getStoreLists, getBanners } from "@/api/store";
import { reloadMessage } from "@/lang";
import { formatMoney } from "@/utils/money";
import { getAreaList } from "@/api/store";
import { treeDataTranslate } from "@/utils/util";
import { mapState } from "vuex";
import hamburger from '@/components/hamburger'
export default {
  components: { hamburger },
  filters: {
    moneyFilter(val) {
      return formatMoney(val);
    },
  },
  data() {
    return {
      logo: require("@/assets/images/logo-03.png"),
      relatedLink: "",
      videoList: [],
      // activityList: homeActivityList(),
      // buttetinList: homeBulletinList(),
      activityList: [],
      buttetinList: [],
      // ids: homeRestIds(),
      ids: [],
      // bannerList: homePhBannerList(),
      bannerList: [],
      shopType: storeShopType(),
      // partnerList: homePartnerList(),
      partnerList: [],
      lanList: homeLanList(),
      // mediaList: homeMediaList(),
      mediaList: [],
      activeName: "1",
      areaList: [],
      actions: [
        { text: "日本語", lan: "ja" },
        { text: "简体中文", lan: "zh" },
        { text: '繁體中文', lan: 'zh_tw' },
        { text: "English", lan: "en" },
        { text: "한국어", lan: "ko" },
      ],
      dinningTypes: [
        { value: 2, label: this.$t("午餐") },
        { value: 3, label: this.$t("晚餐") },
      ],
      minPriceList: minPriceList(),
      maxPriceList: maxPriceList(),
      showPopover: false,
      detailForm: false,
      isShow: true,
      dataList: [],
      searchForm: {
        parentId: 101,
        page: 1,
        pageSize: 10,
      },
      dataList2: [],
    };
  },
  computed: {
    ...mapState(["areaIds"]),
    lan() {
      return this.lanList[localStorage.getItem("locale")];
    },
    language() {
      return localStorage.getItem("locale");
    },
  },
  created() {
    this.getAreaLists();
    // this.getDataList();
    this.getBannersList();
    // if (this.language == 'ko') {
    //   this.mediaList.push({ id: 5, url: require('@/assets/images/footer/media-5.png'), title: '', target: '_blank', link: 'https://blog.naver.com/tripellet' })
    //   if (this.mediaList[2].id == 2 && this.mediaList[1].id == 1) {
    //     this.mediaList[2].link = 'https://www.facebook.com/tripelletnow/'
    //     this.mediaList[1].link = 'https://www.instagram.com/tripellet_now/'
    //   }
    // }
  },
  methods: {
    getAreaLists() {
      getAreaList({ ids: this.areaIds.join(",") }).then((response) => {
        if (response && response.data) {
          this.areaList = treeDataTranslate(
            response.data,
            "areaId",
            "parentId"
          );
        }
      });
    },
    searchHandle() {
      // this.searchForm.page = 1
      // this.getDataList()
    },
    // 地区选择触发
    handleAreaChange(val) {
      if (val && val.length > 0) {
        this.searchForm.areaId = val[val.length - 1];
      }
    },
    showMore() {
      this.dataList2 = this.dataList;
      this.isShow = !this.isShow;
    },
    onSelect(action) {
      console.log(action);
      this.$i18n.locale = action.lan;
      localStorage.setItem("locale", action.lan);
      reloadMessage();
      this.$router.go(0);
    },
    changeVideo(relatedLink) {
      this.relatedLink = relatedLink;
    },
    pageHandle(path, params) {
      if (path === "/index") {
        this.$store.dispatch("resetSearchForm");
      }
      if (params) {
        this.$router.push({ path, query: params });
      } else {
        this.$router.push(path);
      }
    },
    pageHandle2(relatedLink) {
      if (relatedLink.split("/")[2] !== "www.tripellet.com") {
        window.location.href = relatedLink
      }
      else if (relatedLink.indexOf("/") != -1) {
        let url = relatedLink.split("/")[3];
        if(relatedLink.split("/")[4]){
          this.$router.push(`${url}/${relatedLink.split("/")[4]}`);
        }else {
          this.$router.push(url);
        }
      }
    },
    async getBannersList() {
      this.searchForm.coverLan = localStorage.getItem("locale");
      this.videoList = await this.getVideolist(101);
      this.buttetinList = await this.getAllData(143);
      this.partnerList = await this.getAllData(145);
      this.bannerList = await this.getAllData(146);
      this.activityList = await this.getAllData(148);
      this.mediaList = await this.getAllData(149);
      const RestaurantIds = await this.getRestaurant(144)
      this.ids = RestaurantIds.join(',')
      await this.getDataList()
    },
    // 获取所有公用方法
    getAllData(parentId) {
      this.searchForm.parentId = parentId;
      return new Promise((resolve, reject) => {
        getBanners(this.searchForm).then((response) => {
          if (response && response.data) {
            resolve(response.data.list);
          }
        });
      });
    },
    // 获取视频列表默认第一个
    getVideolist(parentId) {
      this.searchForm.parentId = parentId;
      return new Promise((resolve, reject) => {
        getBanners(this.searchForm).then(response => {
          if (response && response.data) {
            // console.log(response.data.list)
            this.relatedLink = response.data.list[0].relatedLink
            resolve(response.data.list)
          }
        });
      });
    },
    // 获取推荐餐厅
    getRestaurant(parentId) {
      this.searchForm.parentId = parentId;
      let RestaurantIdArr = []
      return new Promise((resolve, reject) => {
        getBanners(this.searchForm).then(response => {
          if (response && response.data) {
            console.log(response.data.list)
            response.data.list.forEach(item => {
              RestaurantIdArr.push(Number(item.content))
            })
            resolve(RestaurantIdArr)
          }
        });
      });
    },
    getDataList() {
      const params = {
        ids: this.ids,
        // 测试数据
        // ids: '36,37,38,39,40'
      };
      getStoreLists(params).then((response) => {
        if (response && response.data) {
          this.dataList = response.data;
          this.dataList.map((item) => {
            if (item.storeImg) {
              this.$set(item, "img", item.storeImg.split(",")[0]);
            }
            if (item.storeType) {
              this.$set(item, "storeTypes", item.storeType.split(","));
            }
          });
          this.dataList2 = this.dataList.slice(0, 6);
        }
      });
    },
    // 进入店铺
    detailHandle(id) {
      this.$router.push({ path: "/detail", query: { id } });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .van-col.van-col--8 {
  padding-right: 6.66667px;
}
.cost-btn {
  background: #f9b62a;
  color: #fff;
  margin: 0 4px;
  width: 100%;
}
.flex-box {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-box {
  display: flex;
  width: 100%;
  height: 48px;
  justify-content: space-between;
  align-items: center;
  .nav-left {
    height: 48px;
    display: flex;
    align-items: center;
  }
  .nav-right {
    height: 48px;
    display: flex;
    align-items: center;
  }
}
.color-red {
  color: rgb(245, 108, 108);
}
.van-nav-bar .van-icon {
  color: #333;
  font-size: 24px;
}
.van-notice-bar {
  height: 20px;
  font-size: 12px;
  padding: 0 4px;
}
.container {
  padding: 0 16px;
  box-sizing: border-box;
}
.video-box {
  width: 100%;
  height: auto;
  .video-img-list {
    width: 100%;
    margin-top: 8px;
    margin-bottom: 16px;
    .video-img-item {
      box-sizing: border-box;
      // margin-right: 16px;
      &:first-child {
        // margin-right: 16px;
        margin-left: 16px;
        .video-img {
          box-sizing: border-box;
        }
      }
      &:last-child {
        box-sizing: border-box;
        margin-right: 20px;
        padding-right: 20px;
      }
    }
    .video-img-item2 {
      margin: 0 -30px;
    }
  }
}
.bulletin-box {
  width: 100%;
  height: 55px;
  .buttentin-swiper {
    width: 100%;
    min-height: 50px;
    max-height: 60px;
    // overflow: hidden;
    background: rgb(255, 244, 220);
  }
  .bulletin-content {
    margin: 4px 0;
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 50px;
    // height: auto;
    font-size: 12px;
    line-height: 12px;
  }
}
.activity-box {
  margin-top: 21px;
  margin-bottom: 25px;
  width: 100%;
  // height: 170px;
  ::v-deep .van-image__img {
    border-radius: 4px;
  }
}
.special-box {
  // margin-top: 21px;
  width: 100%;
  height: auto;
  .special-title {
    font-size: 16px;
    font-weight: 600;
  }
  .banner-item {
    margin-top: 8px;
    width: 100%;
    height: 114px;
    border-radius: 4px;
    ::v-deep .van-image__img {
      border-radius: 4px;
    }
  }
}
.content-box {
  margin-top: 10px;
  width: 100%;
  .tab-line {
    display: flex;
    width: 100%;
    .tab-box {
      width: 88%;
      ::v-deep .van-tab__text {
        font-size: 16px;
        color: #000;
        font-weight: 600;
      }
      ::v-deep .van-tabs__line {
        width: 20%;
        background-color: rgb(249, 182, 42);
      }
      ::v-deep .van-tab {
        justify-content: flex-start;
      }
    }
    .tab-title {
      margin: 0 10px;
      display: inline-block;
      height: 44px;
      line-height: 44px;
      font-size: 16px;
      font-weight: 600;
    }
    .active-tab {
      border-bottom: 4px solid rgb(249, 182, 42);
    }
    .tab-all {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 70px;
      height: 44px;
      font-size: 16px;
      font-weight: 600;
    }
  }
  .content-list {
    .content-list-item {
      width: 100%;
      height: 100px;
      margin-top: 10px;
      padding: 6px;
      border-radius: 4px;
      box-sizing: border-box;
      border: 1px solid rgb(245, 245, 245);
      ::v-deep .van-card {
        background-color: transparent;
        padding: 0;
      }
      .content-item-title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 6px;
      }
      .conqtent-item-tag {
        margin-right: 4px;
        margin-bottom: 6px;
        &:last-child {
          margin-right: 0;
        }
      }
      .content-item-price {
        margin-bottom: 6px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }
    }
  }
  .show-more {
    margin-top: 10px;
    width: 100%;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background: rgb(242, 242, 242);
    font-size: 12px;
  }
}
.link-box {
  margin-top: 25px;
  margin-bottom: 25px;
  width: 100%;
  overflow: hidden;
  .link-title {
    font-size: 16px;
    font-weight: 600;
  }
  .link-item {
    margin-top: 10px;
    padding: 6px;
    width: 100%;
    // min-height: 120px;
    box-sizing: border-box;
    border: 1px solid rgb(245, 245, 245);
    .link-title {
      font-size: 12px;
      color: #333;
      text-align: center;
    }
  }
}
.footer-info {
  margin-bottom: 12px;
  text-align: center;
  font-size: 12px;
}
.detail-form {
  padding: 10px;
  box-sizing: border-box;
  ::v-deep .el-form-item {
    width: 100%;
    margin-bottom: 0px;
  }
  ::v-deep .el-form-item__label {
    margin-bottom: 0px;
    padding: 0;
  }
}
.media-box {
  margin-bottom: 60px;
  // text-align: center;
  display: flex;
  justify-content: center;
  .meida-item {
    margin-right: 12px;
    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
