<template>
  <div class="app-container font-test">
    <!-- 顶部菜单栏和视频区 -->
    <div class="top-banner">
      <!-- 顶部菜单 -->
      <div class="top-menu-box">
        <div class="container top-menu">
          <!-- 左边logo -->
          <div class="left-logo">
            <el-image :src="logo" class="logo-img" lazy @click="pageHandle('/home')"></el-image>
          </div>
          <!-- 右边菜单 -->
          <div class="right-menu">
            <el-menu :default-active="activeIndex" class="el-menu-demo bg-t" mode="horizontal">
              <el-menu-item index="1">
                <div class="menu-text">{{ $t("首页") }}</div>
              </el-menu-item>
              <!-- 中文,日语,台湾直接跳去下载页 -->
              <!-- <el-menu-item index="2" @click="pageHandle('/tcapp-download-a')" v-show="language!=='ja'">
                <div class=" menu-text">
                  {{ 'APP'+$t("下载") }}
                </div>
              </el-menu-item> -->
              <el-menu-item index="2" @click="pageHandle('/tcapp-download-b')" v-if="language =='zh'">
                <div class=" menu-text">
                  {{ 'APP'+$t("下载") }}
                </div>
              </el-menu-item>
              <el-menu-item index="2" @click="pageHandle('/tcapp-download-b/tc')" v-if="language =='zh_tw'">
                <div class=" menu-text">
                  {{ 'APP'+$t("下載") }}
                </div>
              </el-menu-item>
              <el-menu-item index="2" @click="pageHandle('/tcapp-download-b/kr')" v-if="language =='ko'">
                <div class=" menu-text">
                  {{ 'APP'+$t("다운로드") }}
                </div>
              </el-menu-item>
              <el-menu-item index="2" @click="pageHandle('/tcapp-download-b')" v-if="language =='en'">
                <div class=" menu-text">
                  {{ 'APP'+$t("Download") }}
                </div>
              </el-menu-item>
              <el-menu-item index="2" @click="pageHandle('/tcapp-download-b')" v-if="language =='ja'">
                <div class=" menu-text">
                  {{ 'APP'+$t("ダウンロード") }}
                </div>
              </el-menu-item>
              <el-menu-item index="3">
                <div class="menu-text" @click="pageHandle('/aboutus')">
                  {{ $t("关于我们") }}
                </div>
              </el-menu-item>
              <!-- <el-menu-item index="4">
                <div class="menu-text" @click="pageHandle('/announcement')">{{ $t('企业公告') }}</div>
              </el-menu-item> -->
              <el-menu-item index="4" v-if="language =='zh'">
                <div class="menu-text" @click="pageHandle('/news')">{{ $t('企业公告') }}</div>
              </el-menu-item>
              <el-menu-item index="4" v-if="language =='zh_tw'">
                <div class="menu-text" @click="pageHandle('/news')">{{ $t('企業公告') }}</div>
              </el-menu-item>
              <el-menu-item index="4" v-if="language =='en'">
                <div class="menu-text" @click="pageHandle('/news')">{{ $t('News') }}</div>
              </el-menu-item>
              <el-menu-item index="4" v-if="language =='ja'">
                <div class="menu-text" @click="pageHandle('/news')">{{ $t('ニュース') }}</div>
              </el-menu-item>
              <el-menu-item index="4" v-if="language =='ko'">
                <div class="menu-text" @click="pageHandle('/news')">{{ $t('기업 뉴스') }}</div>
              </el-menu-item>
              <!-- <el-menu-item index="2"><div class="menu-text" @click="pageHandle('/index')">{{ $t('餐厅列表') }}</div></el-menu-item> -->
              <!-- <el-menu-item index="3"><div class="menu-text" @click="pageHandle('/nearby')">
              <el-image :src="localLogo" style="line-height: 20px;" lazy/>
              {{ $t('附近餐厅') }}</div></el-menu-item> -->
              <el-submenu index="5">
                <template slot="title">
                  <span style="font-size:16px;color: #333;">
                    <el-image :src="lanLogo" style="width: 20px;line-height: 24px;" lazy />
                    {{ lan }}
                  </span>
                </template>
                <el-menu-item index="4-1">
                  <div class="menu-text2" @click="changLan('ja')">
                    日本語
                  </div>
                </el-menu-item>
                <el-menu-item index="4-2">
                  <div class="menu-text2" @click="changLan('zh')">
                    简体中文
                  </div>
                </el-menu-item>
                <el-menu-item index="4-3">
                  <div class="menu-text2" @click="changLan('zh_tw')">
                    繁體中文
                  </div>
                </el-menu-item>
                <el-menu-item index="4-4">
                  <div class="menu-text2" @click="changLan('en')">
                    English
                  </div>
                </el-menu-item>
                <el-menu-item index="4-5">
                  <div class="menu-text2" @click="changLan('ko')">
                    한국어
                  </div>
                </el-menu-item>
              </el-submenu>
            </el-menu>
          </div>
        </div>
      </div>
      <!-- 白边背景 -->
      <div class="top-banner-bg"></div>
      <!-- 右上角背景 -->
      <!-- <div class="top-banner-bg2"></div> -->
      <!-- 搜索条件框 -->
      <div class="searchForm-box">
        <div class="searchForm-title">
          <span>{{ $t("餐厅搜索") }}</span>
          <!-- <span @click="pageHandle('/nearby')" class="near">{{ $t('附近餐厅') }} ></span> -->
        </div>
        <div class="searchForm">
          <el-cascader v-model="searchForm.areaId" class="border-line" style="widht:180px" :placeholder="$t('全部地区')" :options="areaList" :props="{ value: 'areaId', label: 'areaName' }" clearable @change="handleAreaChange" />
          <el-select v-model="searchForm.storeType" clearable :placeholder="$t('全部料理')" style="width: 180px" class="border-line">
            <el-option v-for="(item, name) in shopType" :key="name" :value="name" :label="$t(item)" />
          </el-select>
          <el-select v-model="searchForm.dinningType" :placeholder="$t('午餐与晚餐')" clearable style="width: 180px" class="border-line">
            <el-option v-for="item in dinningTypes" :key="item.value" :value="item.value" :label="$t(item.label)" />
          </el-select>
          <el-select v-model="searchForm.minAmount" :placeholder="$t('无下限')" clearable style="width: 150px" class="border-line">
            <el-option v-for="item in minPriceList" :key="item.value" :value="item.value" :label="$t(item.label)" />
          </el-select>
          <el-select v-model="searchForm.maxAmount" :placeholder="$t('无上限')" clearable style="width: 150px;margin-right: 0" class="border-line">
            <el-option v-for="item in maxPriceList" :key="item.value" :value="item.value" :label="$t(item.label)" />
          </el-select>
          <el-input v-model="searchForm.keywords" style="width: 210px" class="border-line" :placeholder="$t('餐厅名称')" />
          <el-button class="cost-btn banner-btn" size="medium" icon="el-icon-search" @click="pageHandle('/index', searchForm)"></el-button>
        </div>
      </div>
      <!-- 视频区域块 -->
      <div class="video-box">
        <el-image :src="left" class="video-icon" @click="prevVideo"></el-image>
        <div class="video-content">
          <iframe id="videoIframe" width="100%" height="496" :src="relatedLink" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <el-image :src="right" class="video-icon" @click="nextVideo"></el-image>
      </div>
    </div>
    <!-- 中间内容区 -->
    <div class="center-box">
      <!-- 文字公告区 -->
      <div class="bulletin-box container">
        <!-- <div class="bulletin-left">お知らせ</div> -->
        <div class="bulletin-left font-test">{{ $t("公告") }}</div>
        <div class="bulletin-right font-test">
          <swiper class="bulletin-swiper" :options="swiperOption2">
            <swiper-slide v-for="(item, index) in buttetinList" :key="index">
              <div class="bulletin-content" :style="{ color: item.color ? item.color : '' }">
                {{ $t(item.content) }}
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
      <div class="center-bg">
        <!-- <div class="center-bg1"></div> -->
        <div class="center-bg2"></div>
        <div class="center-bg3"></div>
        <!-- tab菜单栏 -->
        <div class="tab-box container">
          <el-tabs v-model="activeName">
            <el-tab-pane name="1">
              <span slot="label" class="tab-title">{{ $t("热门餐厅") }}</span>
            </el-tab-pane>
          </el-tabs>
          <div @click="pageHandle('/nearby')" class="near">
            {{ $t("附近餐厅") }}
          </div>
          <div class="near" @click="pageHandle('/index')">
            {{ $t("餐厅列表") }}
          </div>
          <!-- <div class="near" @click="pageHandle('/department')">
            {{ $t("观光情报") }}
          </div> -->
        </div>
        <!-- 中心内容栏 -->
        <div class="content-box">
          <!-- 左边banner栏 -->
          <div class="left-banner">
            <div class="left-title">
              {{ $t("限时特集") }}
            </div>
            <swiper class="swiper" :options="swiperOption" ref="mySwiper">
              <swiper-slide v-for="item in bannerList" :key="item.id">
                <!-- <a :href="item.path[language]" target="_blank" rel="noopener noreferrer"> -->
                <el-image :src="item.bannerImg" class="banner-item" @click="pageHandle2(item.relatedLink)" />
                <!-- </a> -->
              </swiper-slide>
              <!-- 占位，4个时需要占位 -->
              <swiper-slide> </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>
          <!-- 右边列表栏 -->
          <div class="right-list-box">
            <div class="right-title">
              <el-image :src="down" lazy class="right-img" />{{
                $t("精选餐厅")
              }}
            </div>
            <div class="right-list topnav_box">
              <div v-for="item in dataList" :key="item.id" class="right-item" @click="detailHandle(item.id)">
                <div class="right-item-title">
                  <p class="item-title-title">{{ item.storeName }}</p>
                </div>
                <div class="right-item-content">
                  <!-- 左边文字 -->
                  <div class="content-box">
                    <div class="list-info-item">
                      <span class="font-14 color-green">{{ $t("类型") }}：</span>
                      <span v-if="item.storeTypes && item.storeTypes.length > 0">
                        <span v-for="(type, typeIndex) in item.storeTypes" :key="typeIndex" class="font-14">
                          <span v-show="typeIndex > 0">·</span>
                          {{ $t(shopType[type]) }}
                        </span>
                      </span>
                      <span v-else>-</span>
                    </div>
                    <div class="list-info-item one-line">
                      <span class="font-14 color-green">{{ $t("地址") }}：</span>
                      <span v-if="item.address">{{ item.address }}</span>
                    </div>
                    <div class="list-info-item">
                      <span v-if="item.lunch > 0" class="font-14 color-green">{{ $t("午餐") }}：</span>
                      <span v-if="item.lunch > 0">¥ {{ item.lunch | moneyFilter }}</span>
                      <span v-if="item.maxLunch > 0">- {{ item.maxLunch | moneyFilter }}</span>
                      <span v-if="item.dinner > 0" class="font-14 color-green">{{ $t("晚餐") }}：</span>
                      <span v-if="item.dinner > 0">¥ {{ item.dinner | moneyFilter }}</span>
                      <span v-if="item.maxDinner > 0">- {{ item.maxDinner | moneyFilter }}</span>
                    </div>
                    <div class="list-info-item two-line">
                      <span class="font-14 color-green">{{ $t("特典提供") }}：</span>
                      <span v-if="item.featureProvide">{{
                        item.featureProvide
                      }}</span>
                      <span v-else>-</span>
                    </div>
                  </div>
                  <!-- 右边图片 -->
                  <div class="img-box">
                    <el-image :src="item.img" class="right-item-img"></el-image>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部合作伙伴区 -->
    <div class="partner-box">
      <div class="partner-title container">
        {{ $t("相關链接") }}
      </div>
      <!-- 合作伙伴列表 -->
      <div class="partner-list container">
        <div v-for="(item, index) in partnerList" :key="item.id" class="partner-item" :style="index == partnerList.length - 1 ? 'margin-right: 0;' : ''">
          <a :href="item.relatedLink" target="_blank">
            <el-image :src="item.bannerImg" lazy style="width: 100%;" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { storeShopType, minPriceList, maxPriceList } from '@/utils/public_data'
import { getAreaList } from '@/api/store'
import { treeDataTranslate } from '@/utils/util'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { homeBannerList, homeLanList, homePartnerList, homeBulletinList, homeRestIds } from '@/utils/public_data'
import { reloadMessage } from '@/lang'
import { getStoreLists, getBanners } from '@/api/store'
import { formatMoney } from '@/utils/money'
import 'swiper/dist/css/swiper.css'
import { mapState } from 'vuex'
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  filters: {
    moneyFilter(val) {
      return formatMoney(val)
    },
  },
  data() {
    return {
      logo: require('@/assets/images/logo-03.png'),
      activeIndex: '1',
      lanLogo: require('@/assets/images/lan.png'),
      left: require('@/assets/images/left.png'),
      right: require('@/assets/images/right.png'),
      down: require('@/assets/images/down.png'),
      topBg: require('@/assets/images/bg/bg1.png'),
      localLogo: require('@/assets/images/local.png'),
      lanList: homeLanList(),
      searchForm: {
        keywords: null,
        parentId: 101,
        page: 1,
        pageSize: 10,
      },
      shopType: storeShopType(),
      dinningTypes: [
        { value: 2, label: this.$t('午餐') },
        { value: 3, label: this.$t('晚餐') },
      ],
      minPriceList: minPriceList(),
      maxPriceList: maxPriceList(),
      showDownload: true,
      relatedLink: '',
      areaList: [],
      activeName: '1',
      swiperOption: {
        slidesPerView: 1,
        slidesPerColumn: 3,
        spaceBetween: 10,
        speed: 1000,
        autoplay: 7000,
        pagination: '.swiper-pagination',
        paginationClickable: true, //允许点击分页跳转
        observer: true,
        observerParents: true,
      },
      swiperOption2: {
        direction: 'vertical',
        spaceBetween: 30,
        loop: true,
        autoplay: 7000,
        centeredSlides: true,
      },
      // bannerList: homeBannerList(),
      bannerList: [],
      dataList: [],
      // partnerList: homePartnerList(),
      // buttetinList: homeBulletinList(),
      buttetinList: [],
      partnerList: [],
      videoIndex: 0,
      // ids: homeRestIds(),
      ids: [],
      videoList: [],
    }
  },
  computed: {
    lan() {
      return this.lanList[localStorage.getItem('locale')]
    },
    language() {
      return localStorage.getItem('locale')
    },
    ...mapState(['areaIds']),
  },
  created() {
    // console.log(dealWeekDay([1, 2, 3, 4, 6,7, 10]))


    this.getAreaLists()
    this.getBannersList()
    // this.init()
  },
  methods: {
    // 进入企业公告
    // detailHandle() {
    //   this.$router.push({ path: '/Announcement'})
    // },
    // 进入店铺
    detailHandle(id) {
      this.$router.push({ path: '/detail', query: { id } })
    },
    init() {
      this.getDataList()
    },
    pageHandle(path, params) {
      if (path === '/index') {
        this.$store.dispatch('resetSearchForm')
      }
      if (params) {
        console.log(path, params)
        // this.$router.push({ path, query: { search: JSON.stringify(params) }})
        this.$router.push({ path, query: params })
      } else {
        this.$router.push(path)
      }
    },
    pageHandle2(relatedLink) {
      console.log('跳转',relatedLink);
      if (relatedLink.split("/")[2] !== "www.tripellet.com") {
        window.location.href = relatedLink
      }
      else if (relatedLink.indexOf("/") != -1) {
        let url = relatedLink.split("/")[3];
        if(relatedLink.split("/")[4]){
          this.$router.push(`${url}/${relatedLink.split("/")[4]}`);
        }else{
          this.$router.push(url);
        }
        // if (url === 'exploreallpass') {
        //   this.$router.push(`${relatedLink.split("/")[3]}/${relatedLink.split("/")[4]}`)
        // } else if(url === 'hfkansai'){
        //   this.$router.push(`${url}/${relatedLink.split("/")[4]}`);
        // }else if(url === 'hffukuoka'){
        //   this.$router.push(`${url}/${relatedLink.split("/")[4]}`);
        // }else {
        //   this.$router.push(url);
        // }
      }
    },
    async getBannersList() {
      this.searchForm.coverLan = localStorage.getItem("locale");
      this.videoList = await this.getVideolist(101)
      this.buttetinList = await this.getAllData(143);
      this.partnerList = await this.getAllData(145);
      this.bannerList = await this.getAllData(147);
      // console.log('现时特技',this.bannerList);
      const RestaurantIds = await this.getRestaurant(144)
      this.ids = RestaurantIds.join(',')
      await this.init()
    },
    getDataList() {
      const params = {
        ids: this.ids
        // 测试数据
        // ids: '36,37,38,39,40'
      }
      console.log(params)
      getStoreLists(params).then((response) => {
        if (response && response.data) {
          this.dataList = response.data
          // console.log(this.dataList)
          this.dataList.map((item) => {
            if (item.storeImg) {
              this.$set(item, 'img', item.storeImg.split(',')[0])
            }
            if (item.storeType) {
              this.$set(item, 'storeTypes', item.storeType.split(','))
            }
          })
        }
      })
    },
    nextVideo() {
      this.videoIndex++
      if (this.videoIndex > this.videoList.length - 1) {
        this.videoIndex = 0
      }
      this.relatedLink = this.videoList[this.videoIndex].relatedLink
    },
    prevVideo() {
      this.videoIndex--
      if (this.videoIndex < 0) {
        this.videoIndex = this.videoList.length - 1
      }
      this.relatedLink = this.videoList[this.videoIndex].relatedLink
    },
    changLan(val) {
      // 设置语言
      this.$i18n.locale = val
      localStorage.setItem('locale', val)
      reloadMessage()
      this.$router.go(0)
    },
    getAreaLists() {
      getAreaList({ ids: this.areaIds.join(',') }).then((response) => {
        if (response && response.data) {
          this.areaList = treeDataTranslate(response.data, 'areaId', 'parentId')
        }
      })
    },
    // 地区选择触发
    handleAreaChange(val) {
      if (val && val.length > 0) {
        this.searchForm.areaId = val[val.length - 1]
      }
    },
    // 获取列表公用方法
    getAllData(parentId) {
      this.searchForm.parentId = parentId;
      return new Promise((resolve, reject) => {
        getBanners(this.searchForm).then(response => {
          if (response && response.data) {
            resolve(response.data.list);
          }
        });
      });
    },
    // 获取视频列表默认第一个
    getVideolist(parentId) {
      this.searchForm.parentId = parentId;
      return new Promise((resolve, reject) => {
        getBanners(this.searchForm).then(response => {
          if (response && response.data) {
            // console.log(response.data.list)
            this.relatedLink = response.data.list[0].relatedLink
            resolve(response.data.list)
          }
        });
      });
    },
    // 获取推荐餐厅
    getRestaurant(parentId) {
      this.searchForm.parentId = parentId;
      let RestaurantIdArr = []
      return new Promise((resolve, reject) => {
        getBanners(this.searchForm).then(response => {
          if (response && response.data) {
            // console.log(response.data.list)
            response.data.list.forEach(item => {
              RestaurantIdArr.push(Number(item.content))
            })
            resolve(RestaurantIdArr)
          }
        });
      });
    },
  },
}
</script>
<style lang="scss" scoped>
.font-test {
  font-family: "GenJyuuGothic";
}
.test {
  z-index: 0;
}
.menu-text2 {
  text-align: center;
  height: 36px;
  line-height: 36px;
  overflow: hidden;
  color: #333;
  font-size: 14px;
  &:hover {
    cursor: pointer;
    background-color: #fff4dc;
    color: #ffbb2c;
  }
}
.font-14 {
  font-size: 14px;
}
.color-green {
  color: #6aaf38;
}
.one-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.two-line {
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.top-banner {
  position: relative;
  height: 780px;
  // background: linear-gradient(to right, #ffc74f, #ffc548, #ffc542, #ffbb2a, #ffc034);
  // background-size: 110% 110%;
  // background-position: 20% 300%;
  background: #fff4dc;
  z-index: 0;
  overflow: hidden;
  .top-banner-bg {
    position: absolute;
    width: 100%;
    height: 56px;
    left: 0;
    top: 80px;
    background: url("../../assets/images/bg/bg6.png") no-repeat;
    z-index: -11;
  }
  .top-banner-bg2 {
    position: absolute;
    right: -100px;
    top: -100px;
    width: 600px;
    height: 600px;
    background: #ffc64e;
    transform: rotate(21deg);
    border-radius: 40px;
    z-index: -1;
  }
}
.container {
  width: 1000px;
  margin: 0 auto;
}
.top-menu-box {
  background: #fff;
}
.top-menu {
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo-img {
    width: 140px;
    cursor: pointer;
  }
  .lan-logo {
    width: 21px;
  }
}
.bg-t {
  background: rgba(255, 255, 255, 0);
}
.el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background-color: rgba(255, 255, 255, 0);
  // color: #FFBB2C;
}
.el-menu--horizontal > .el-menu-item.is-active {
  // border-bottom: 2px solid #fff;
  color: #ffbb2c;
}
// .el-menu--horizontal .el-menu .el-menu-item, .el-menu--horizontal .el-menu .el-submenu__title,  .el-menu--horizontal .el-menu .el-submenu__title:hover{
//   background-color: rgba(255,255,255,0);
// }
// ::v-deep .el-submenu {
//   background-color: rgba(255,255,255,0);
// }
// :v-deep .el-submenu__title {
//   color: #fff;
//   background-color: rgba(255,255,255,0);
// }
// .el-submenu__title:hover {
//   background-color: rgba(255,255,255,0);
// }
// ::v-deep .el-menu {
//   background-color: rgba(255,255,255,0);
// }
// ::v-deep .el-menu-item {
//   color: #fff;
//   background-color: rgba(255,255,255,0);
//   &:hover, &:focus {
//     background-color: rgba(255,255,255,0);
//   }
// }
::v-deep .el-menu--popup {
  // background-color: rgba(255,255,255,0);
  min-width: 100px;
}
::v-deep .el-input__inner {
  border: 0;
}
.searchForm-box {
  box-sizing: border-box;
  padding: 16px 30px;
  width: 1000px;
  height: 100px;
  margin: 0 auto;
  margin-top: 10px;
  background: #fff;
  border-radius: 15px;
  -moz-box-shadow: 2px 2px 10px rgba(248, 182, 42, 0.5);
  -webkit-box-shadow: 2px 2px 10px rgba(248, 182, 42, 0.5);
  box-shadow: 2px 2px 10px rgba(248, 182, 42, 0.5);
  .searchForm-title {
    display: flex;
    justify-content: space-between;
  }
}
.searchForm {
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  border: 1px solid #333;
  border-radius: 5px;
  background: #fff4dc;
  .border-line {
    border-right: 1px solid #333;
  }
}
.near {
  cursor: pointer;
  // &:hover {
  //   color: #E7B530;
  // }
  line-height: 40px;
  padding: 0px 20px;
  text-align: center;
  font-size: 24px;
  // font-weight: 600;
  color: #333;
}
.video-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 1000px;
  height: 534px;
  margin: 24px auto 0px;
  background: #fff;
  border: 2px solid #dcdfe6;
  border-radius: 50px;
  z-index: 1000;
  .video-content {
    width: 880px;
    height: 496px;
    z-index: 100;
  }
}
.video-icon {
  width: 34px;
  cursor: pointer;
}
.bulletin-box {
  display: flex;
  width: 1000px;
  height: 45px;
  border: 1px solid #f8b62a;
  border-radius: 15px;
  .bulletin-left {
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 70px;
    width: auto;
    height: 100%;
    background: #f8b62a;
    border-radius: 15px;
    color: #fff;
  }
}
.center-box {
  position: relative;
  width: 100%;
  background: #fff4dc;
  background-size: 140% 100%;
  background-position: -700px 0px;
  z-index: 0;
  .center-bg {
    margin-top: 30px;
    background: #f9f9f9;
    height: 1286px;
    overflow: hidden;
    z-index: 0;
    .center-bg1 {
      position: absolute;
      left: 0;
      top: 0px;
      width: 260px;
      height: 900px;
      background: url("../../assets/images/bg/bg2.png") no-repeat;
      z-index: 1;
    }
    .center-bg2 {
      position: absolute;
      top: 200px;
      right: 0;
      width: 280px;
      height: 550px;
      background: url("../../assets/images/bg/bg4.png") no-repeat;
      z-index: 1;
    }
    .center-bg3 {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 280px;
      height: 418px;
      background: url("../../assets/images/bg/bg3.png") no-repeat;
      z-index: 1;
    }
  }
}
.tab-box {
  margin-top: 30px;
  display: flex;
  ::v-deep .el-tabs__active-bar {
    color: #f8b62a;
    background-color: #f8b62a;
  }
  ::v-deep .is-active {
    color: #f8b62a;
    font-size: 6px;
  }
  ::v-deep .el-tabs__item {
    text-align: center;
    padding: 0px 10px;
    margin: 0;
    height: 45px;
    &:hover {
      color: #f8b62a;
    }
  }
  ::v-deep .is-active {
    color: #f8b62a;
  }
  ::v-deep .el-tabs__active-bar {
    text-align: center;
    margin: 0 auto;
    height: 4px;
    color: #f8b62a;
    background-color: #f8b62a;
  }
  ::v-deep .el-tabs__nav-wrap::after {
    background-color: rgba(255, 255, 255, 0);
  }
  .tab-title {
    padding: 0px 20px;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
  }
}
.content-box {
  width: 1000px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  z-index: 100;
  .left-banner {
    width: 486px;
    height: 1078px;
    border: 2px solid #e7b530;
    border-radius: 50px;
    z-index: 100;
    .left-title {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      border: 0;
      width: 100%;
      height: 68px;
      border-radius: 47px 47px 0 0;
      background: #e7b530;
      color: #fff;
      font-size: 24px;
      background: url("../../assets/images/bg/bg5.png");
    }
  }
}
.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 4px solid #ffbb2c;
  // padding: 0 10px;
}
.swiper {
  margin-top: 30px;
  width: 100%;
  height: 900px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  .swiper-slide {
    text-align: center;
    width: 400px;
    height: 250px;
  }
}
.bulletin-right {
  width: 90%;
}
.bulletin-swiper {
  margin-left: 20px;
  width: 100%;
  height: 45px;
  .swiper-slide {
    width: 100%;
    height: 45px;
  }
}
.menu-text {
  font-size: 16px;
  color: #333;
}
.right-list-box {
  width: 476px;
  z-index: 100;
  .right-title {
    display: flex;
    font-size: 23px;
    border-bottom: 3px solid #f8b62a;
    line-height: 20px;
    color: #f8b62a;
    padding-bottom: 13px;
    .right-img {
      margin-right: 20px;
      width: 30px;
      height: 24px;
    }
  }
  //滚动条整体部分
  .topnav_box::-webkit-scrollbar {
    width: 5px;
    height: 10px;
  }
  //scroll轨道背景
  .topnav_box::-webkit-scrollbar-track {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    -moz--box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    -o--box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    background-color: transparent;
  }
  //滚动条中能上下移动的小块
  // .topnav_box::-webkit-scrollbar-thumb{
  //      background-color: rgba(0,0,0,.05);
  //      border-radius: 10px;
  //     //  -webkit-box-shadow: inset1px1px0rgba(0,0,0,.1);
  //   }
  /*鼠标悬浮在该类指向的控件上时滑块的样式*/
  .topnav_box:hover::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1);
    -moz--box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1);
    -o--box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1);
    box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1);
  }
  .right-list {
    width: 100%;
    height: 1058px;
    overflow: scroll;
    overflow-x: hidden; //左右有超出内容
    .right-item {
      margin: 18px 0;
      width: 99%;
      height: 186px;
      border-radius: 25px;
      border: 2px solid #f8b62a;
      z-index: 100;
      cursor: pointer;
      .right-item-title {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background: #f8b62a;
        width: 100%;
        height: 40px;
        border: 0;
        padding: 0;
        padding-left: 20px;
        box-sizing: border-box;
        border-radius: 22px 22px 0 0;
        color: #fff;
        font-size: 19px;
        .item-title-title {
          width: 466px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .right-item-content {
        padding: 0 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        width: 480px;
        height: 160px;
        .content-box {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          width: 314px;
          height: 130px;
          margin-top: 20px;
          .list-info-item {
            line-height: 1.5;
            width: 314px;
            font-size: 14px;
          }
        }
        .img-box {
          width: 100px;
          height: 120px;
          .right-item-img {
            width: 100px;
            height: 100px;
          }
        }
      }
    }
  }
}
.banner-item {
  border-radius: 35px;
  width: 400px;
  height: 250px;
  cursor: pointer;
}
.partner-box {
  width: 100%;
  // height: 600px;
  background: #fff4dc;
  overflow: hidden;
  z-index: 1;
  .partner-title {
    margin-top: 78px;
    width: 1000px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    background-image: linear-gradient(to right, #fff4dc, #f8b62a, #fff4dc);
    font-size: 22px;
  }
}
.partner-list {
  margin-top: 41px;
  padding-left: 35px;
  display: flex;
  margin-bottom: 53px;
  // justify-content: center;
  flex-wrap: wrap;
  .partner-item {
    margin-right: 25px;
    margin-bottom: 25px;
    width: 170px;
    height: 170px;
    background: #fbf8fb;
  }
}
.bulletin-content {
  width: 96%;
  padding: 0 10px;
  box-sizing: border-box;
  height: 45px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.banner-btn {
  background: #ffbb2c;
  color: #fff;
  border: 1px solid #ffbb2c;
  border-radius: 0px 5px 5px 0;
}
</style>
<style lang="scss">
.el-menu--collapse .el-menu .el-submenu,
.el-menu--popup {
  min-width: 100px;
}
.swiper-pagination-bullet-active {
  opacity: 1;
  background: #f8b62a;
}
</style>
