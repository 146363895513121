<template>
  <div>
    <div class="hamburger" :class="[!isActive?'active':'']" @click="hamburger()">
      <span class="line"></span>
      <span class="line"></span>
      <span class="line"></span>
    </div>
    <div class="active-mask" v-if="hidden" @click="close()">
      <div class="active-box">
        <el-link class="active-link" href="/home">{{ $t("首页") }}</el-link>
        <el-link class="active-link" v-if="language == 'zh'" href="/tcapp-download-b">{{ "APP" + $t("下载") }}</el-link>
        <el-link class="active-link" v-if="language == 'zh_tw'" href="/tcapp-download-b">{{ "APP" + $t("下載") }}</el-link>
        <el-link class="active-link" v-if="language == 'ko'" href="/tcapp-download-b">{{ "APP" + $t("다운로드") }}</el-link>
        <el-link class="active-link" v-if="language == 'en'" href="/tcapp-download-b">{{ "APP" + $t("Download") }}</el-link>
        <el-link class="active-link" v-if="language == 'ja'" href="/tcapp-download-b">{{ "APP" + $t("ダウンロード") }}</el-link>
        <el-link class="active-link" v-if="language == 'zh'" href="/aboutus">{{ $t('关于我们') }}</el-link>
        <el-link class="active-link" v-if="language == 'zh_tw'" href="/aboutus">{{ $t('關於我們') }}</el-link>
        <el-link class="active-link" v-if="language == 'en'" href="/aboutus">{{ $t('About us') }}</el-link>
        <el-link class="active-link" v-if="language == 'ko'" href="/aboutus">{{ $t('About us') }}</el-link>
        <el-link class="active-link" v-if="language == 'ja'" href="/aboutus">{{ $t('About us') }}</el-link>
        <el-link class="active-link" v-if="language == 'zh'" href="/news">{{ $t('企业公告') }}</el-link>
        <el-link class="active-link" v-if="language == 'zh_tw'" href="/news">{{ $t('企業公告') }}</el-link>
        <el-link class="active-link" v-if="language == 'en'" href="/news">{{ $t('News') }}</el-link>
        <el-link class="active-link" v-if="language == 'ko'" href="/news">{{ $t('기업 뉴스') }}</el-link>
        <el-link class="active-link" v-if="language == 'ja'" href="/news">{{ $t('ニュース') }}</el-link>
      </div>
    </div>
  </div>
</template>

<script>
import { reloadMessage } from "@/lang"
export default {
  data() {
    return {
      isActive: true,
      hidden: false,
    };
  },
  computed: {
    lan() {
      return this.lanList[localStorage.getItem("locale")];
    },
    language() {
      return localStorage.getItem("locale") || "zh_tw";
    },
  },
  methods: {
    hamburger() {
      this.isActive = !this.isActive
      this.isActive ? this.hidden = false : this.hidden = true
    },
    close() {
      this.hidden = false
      this.isActive = !this.isActive
    },
    changLan(val) {
      // 设置语言
      this.$i18n.locale = val;
      localStorage.setItem("locale", val);
      reloadMessage();
      this.$router.go(0);
    },
  },
};
</script>

<style lang="scss" scoped>
.hamburger {
  margin-right: 20px;
}
.hamburger .line {
  width: 20px;
  height: 2px;
  background-color: #000;
  border-radius: 1px;
  display: block;
  margin: 4px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.hamburger:hover {
  cursor: pointer;
}

.hamburger.active .line:nth-child(1) {
  -webkit-transform: translateY(6px) rotate(45deg);
  -ms-transform: translateY(6px) rotate(45deg);
  -o-transform: translateY(6px) rotate(45deg);
  transform: translateY(6px) rotate(45deg);
}

.hamburger.active .line:nth-child(2) {
  opacity: 0;
}

.hamburger.active .line:nth-child(3) {
  -webkit-transform: translateY(-6px) rotate(-45deg);
  -ms-transform: translateY(-6px) rotate(-45deg);
  -o-transform: translateY(-6px) rotate(-45deg);
  transform: translateY(-6px) rotate(-45deg);
}
.active-mask {
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-backdrop-filter: blur(32px);
  backdrop-filter: blur(32px);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 48px;
  text-align: center;
  z-index: 1;
}
.active-box {
  width: 100%;
  height: auto;
  background-color: #f6f6f6;
  position: fixed;
  left: 0;
  top: 0;
  text-align: center;
}
.active-link {
  display: block;
  font-size: 16px;
  margin-bottom: 20px;
  margin-top: 20px;
  color: #606266;
}
.active-link:active {
  color: #606266;
  border-bottom: 1px solid #f6f6f6 !important ;
}
</style>